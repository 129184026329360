import React, { useState, useContext, useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import PropTypes from 'prop-types';
import { CreatableSelect, Select } from 'components/shared/FormSelect';
import { find } from 'lodash';
import FormInput from '../../FormInput';
import PaddingInput from '../../PaddingInput';
import htmlUtils from '../../../../lib/html';
import Indicator from './Indicator';
import Constants from '../../../Constants';
import TextInput from 'react-autocomplete-input';
import { HtmlBuilderContext } from '../../../producer/email/EmailBodyHtml';
import { MAlert } from '../../../shared/Alerts';
import { useAllDynamicContentById } from '../../../../lib/hooks/useDynamicContent';
import { useTemplates } from 'lib/hooks/useTemplate';
import { ReactComponent as TextAlignLeftIcon } from 'svg/text_align_left.svg';
import { ReactComponent as TextAlignCenterIcon } from 'svg/text_align_center.svg';
import { ReactComponent as TextAlignRightIcon } from 'svg/text_align_right.svg';
import InputColorSelect from 'components/lib/InputColorSelect';
import { SourceIcon, TypeIcon } from 'components/producer/templates/AttachedTemplatesList';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';
import { useFlags } from 'launchdarkly-react-client-sdk';

function DraggableButton({ buttonAlignment, buttonUrl, style, text, previewedText, isNewNode }) {
  const { dynamicContentById } = useAllDynamicContentById();
  const [isReceivingData, setIsReceivingData] = useState(false);
  const [isPreviewed, setIsPreviewed] = useState(previewedText.length > 0 ? true : false);
  const [allTextDc, setAllTextDc] = useState({});
  const { testResult, setIsFetchingDc, setTestResult, setAllDynamicContent, allDynamicContent, onPreviewDcModal } =
    useContext(HtmlBuilderContext);
  const { id } = useNode((node) => ({
    id: node.id,
  }));

  const {
    actions: { selectNode },
    isActive,
    isHover,
  } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
    isHover: query.getEvent('hovered').contains(id),
  }));

  const {
    actions: { setProp },
    connectors: { connect, drag },
  } = useNode((state) => ({
    hasDraggedNode: state.events.dragged,
    hasSelectedNode: state.events.selected,
  }));

  useEffect(() => {
    if (isNewNode) {
      selectNode(id);
      setProp((props) => {
        props.isNewNode = false;
      });
    }
  }, []);

  useEffect(() => {
    if (dynamicContentById) {
      const allTextDc = Object.values(dynamicContentById)
        .filter((dc) => dc.dynamic_content_type === Constants.DynamicContentTypes.TEXT)
        .reduce((dynamicContentObj, item) => {
          dynamicContentObj[item.name] = { ...item };
          return dynamicContentObj;
        }, {});
      setAllTextDc(allTextDc);
    }
  }, [dynamicContentById]);

  const onButtonClick = (e) => {
    e.preventDefault();
  };

  // on preview dynamic content
  function onPreviewDc() {
    if (isReceivingData) {
      setIsReceivingData(false);
      setIsFetchingDc(false);
    } else {
      setIsReceivingData(true);

      // extract all dynamic content from text and create object
      const textDcKey = [...text.matchAll(/\{\{(\w+)\}\}/g)].map((match) => match[1]);
      const allDynamicContent = textDcKey.reduce((dynamicContentObj, key) => {
        if (allTextDc[key]) {
          dynamicContentObj[key] = allTextDc[key];
        }
        return dynamicContentObj;
      }, {});
      if (Object.keys(allDynamicContent).length > 0) {
        setAllDynamicContent(allDynamicContent);
      } else {
        setIsReceivingData(false);
        MAlert('No Dynamic Content to preview', 'Error', 'error');
      }
    }
  }

  // when allDynamicContent is set open inputs modal
  useEffect(() => {
    if (isReceivingData && Object.keys(allDynamicContent).length > 0) {
      onPreviewDcModal();
    }
  }, [allDynamicContent]);

  // on test result return
  useEffect(() => {
    if (isReceivingData && Object.keys(testResult).length > 0) {
      if (testResult?.status === 'error') {
        MAlert(`${testResult.message}`, 'Error', 'error');
        setIsFetchingDc(false);
      } else {
        let updatePreviewedText = text;
        Object.keys(testResult).forEach((key) => {
          const placeholder = `\\{\\{${key}\\}\\}`;
          const regex = new RegExp(placeholder, 'gi');
          updatePreviewedText = updatePreviewedText.replace(regex, testResult[key]);
        });

        setProp((props) => {
          props.previewedText = updatePreviewedText;
        });
        setIsPreviewed(true);
        setIsFetchingDc(false);
      }
      setIsReceivingData(false);
      setTestResult({});
    }
  }, [testResult]);

  // if text is changed and previewed, reset previewed
  useEffect(() => {
    if (isPreviewed) {
      setIsPreviewed(false);
      setProp((props) => {
        props.previewedText = '';
      });
    }
  }, [text]);

  return (
    <div ref={(dom) => connect(dom)} className="is-relative is-transparent">
      {(isActive || isHover) && (
        <Indicator
          borderWidth={style.borderWidth}
          borderRadius={style.borderRadius}
          drag={drag}
          isActive={isActive}
          nodeId={id}
          tabText="Button"
          onPreviewDc={onPreviewDc}
          isLoading={isReceivingData}
          isPreviewed={isPreviewed}
        />
      )}
      <div style={{ textAlign: buttonAlignment }}>
        <button className="button is-secondary" style={style} onClick={onButtonClick} href={buttonUrl}>
          {isPreviewed ? previewedText : text ? text : <p className="dc-font placeholder">Enter Button Label</p>}
        </button>
      </div>
    </div>
  );
}

DraggableButton.craft = {
  props: {
    buttonAlignment: 'center',
    previewedText: '',
    buttonUrl: '',
    target: '_blank',
    isNewNode: true,
    style: {
      backgroundColor: '#FFFFFF', // $matik-white
      borderColor: '#E0E5EE', // $grey-300,
      borderRadius: '4px',
      borderStyle: 'solid',
      borderWidth: '1px',
      boxShadow: '0px 2px 10px rgba(0,31,53,0.05)',
      color: '#001F35', // $matik-black,
      cursor: 'pointer',
      fontSize: '14px',
      height: '36px',
      paddingBottom: '6px',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '6px',
      position: 'relative',
      textDecoration: 'none',
      width: '25%',
      zIndex: 10,
    },
    text: '',
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
  related: {
    settings: ButtonSettings,
  },
};

DraggableButton.propTypes = {
  buttonUrl: PropTypes.string,
  previewedText: PropTypes.string,
  buttonAlignment: PropTypes.oneOf(['left', 'right', 'center']),
  style: PropTypes.object,
  text: PropTypes.string,
  isNewNode: PropTypes.bool,
};

function ButtonSettings() {
  const {
    actions: { setProp },
    buttonAlignment,
    backgroundColor,
    borderColor,
    borderRadius,
    borderWidth,
    buttonUrl,
    color,
    fontFamily,
    fontSize,
    height,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    text,
    width,
  } = useNode((node) => ({
    buttonAlignment: node.data.props.buttonAlignment,
    backgroundColor: node.data.props.style.backgroundColor,
    borderColor: node.data.props.style.borderColor,
    borderRadius: parseInt(node.data.props.style.borderRadius),
    borderWidth: parseInt(node.data.props.style.borderWidth),
    buttonUrl: node.data.props.buttonUrl,
    color: node.data.props.style.color,
    fontFamily: node.data.props.style.fontFamily,
    fontSize: parseInt(node.data.props.style.fontSize),
    height: parseInt(node.data.props.style.height),
    paddingBottom: parseInt(node.data.props.style.paddingBottom),
    paddingLeft: parseInt(node.data.props.style.paddingLeft),
    paddingRight: parseInt(node.data.props.style.paddingRight),
    paddingTop: parseInt(node.data.props.style.paddingTop),
    text: node.data.props.text,
    width: parseInt(node.data.props.style.width),
  }));
  const [attachmentType, setAttachmentType] = useState({ label: 'URL', value: 'url' });
  const [attachmentTemplate, setAttachmentTemplate] = useState();
  const { dynamicContentById } = useAllDynamicContentById();
  const { enableAttachmentLinks } = useFlags();
  const { elements: templates } = useTemplates(0, Constants.PAGE_SIZE, null);
  const filteredTemplateOptions =
    templates?.filter(
      (template) =>
        template.source_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL &&
        !Constants.TEMPLATE_SOURCE_TYPES.SPREADSHEET_TYPES.includes(template.source_type),
    ) || [];
  const attachmentTemplateOptions = filteredTemplateOptions?.map((template) => ({
    label: template.name,
    value: template.id,
    source_type: template.source_type,
  }));

  const attachmentTypeOptions = [
    { label: 'URL', value: 'url' },
    { label: 'Matik Template', value: 'template' },
  ];

  useEffect(() => {
    if (buttonUrl.length && buttonUrl.indexOf('template_') >= 0 && attachmentType.value !== 'template') {
      const attachedTemplate = attachmentTemplateOptions.filter(
        (template) => template.value === parseInt(buttonUrl.split('template_')?.[1]),
      );
      setAttachmentType(attachmentTypeOptions[1]);
      setAttachmentTemplate(attachedTemplate[0]);
    }
  }, []);

  const onUpdateFontSize = (obj) => {
    const value = obj.value;
    setProp((props) => {
      props.style.fontSize = `${value}px`;
    });
  };

  const onUpdateFontFamily = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      setProp((props) => {
        props.style.fontFamily = value;
      });
    }
  };

  const onButtonAlignmentChange = (e) => {
    setProp((props) => {
      props.buttonAlignment = e;
    });
  };

  const onColorChange = (propName) => (color) => {
    setProp((props) => {
      props.style[propName] = color;
    });
  };

  const onUpdatePixelInputValue = (e, propName) => {
    const value = e.target.value;
    setProp((props) => {
      props.style[propName] = `${value}px`;
    });
  };

  const onUpdatePercentInputValue = (e, propName) => {
    const value = e.target.value;
    setProp((props) => {
      props.style[propName] = `${value}%`;
    });
  };

  const onPaddingChange = (num, paddingProp) => {
    setProp((props) => {
      props.style[paddingProp] = `${num}px`;
    });
  };

  const validDynamicContent = Object.values(dynamicContentById ?? {}).filter(
    (dc) => dc.dynamic_content_type === Constants.DynamicContentTypes.TEXT,
  );

  const autoCompleteOptions = validDynamicContent.map((content) => `{{${content.name}}}`);

  const fontSizeOptions = htmlUtils.constants.fontSizeOptions;
  const fontFamilyOptions = htmlUtils.constants.fontFamilyOptions;

  const onButtonUrlChange = (value) => {
    if (attachmentType.value === 'template') {
      setAttachmentTemplate(value);
      setProp((props) => (props.buttonUrl = `template_${value.value}`));
    } else {
      setProp((props) => (props.buttonUrl = value));
    }
  };

  const formatTemplatesOptionLabel = (option) => {
    if (Object.keys(option).length) {
      return (
        <>
          <div className="flex items-center gap-1">
            <>
              <SourceIcon sourceType={option.source_type} />
              <TypeIcon sourceType={option.source_type} />
            </>
            <TextOverflowWithTooltip className="leading-6 truncate pl-2">{option.label}</TextOverflowWithTooltip>
          </div>
        </>
      );
    }
  };

  return (
    <div className="module-settings-container">
      <div className="mbs">
        <label>Button Label</label>
        <TextInput
          changeOnSelect={(trigger, val) => val}
          className="html-builder-text-input input"
          Component="input"
          matchAny={true}
          maxOptions={0}
          offsetY={20}
          onChange={(value) => setProp((props) => (props.text = value))}
          options={autoCompleteOptions}
          placeholder="Enter Button Label"
          trigger="{{"
          value={text}
        />
      </div>
      {enableAttachmentLinks && (
        <div className="mbs">
          <label>Link to</label>
          <Select
            classNamePrefix="matik-select"
            onChange={(option) => {
              setAttachmentType(option);
              setProp((props) => (props.buttonUrl = ''));
            }}
            value={attachmentType}
            options={attachmentTypeOptions}
            isMulti={false}
          />
        </div>
      )}

      <div>
        <label>Button URL</label>
        {attachmentType.value === 'template' ? (
          <Select
            classNamePrefix="matik-select"
            onChange={onButtonUrlChange}
            value={attachmentTemplate}
            options={attachmentTemplateOptions}
            isMulti={false}
            formatOptionLabel={formatTemplatesOptionLabel}
          />
        ) : (
          <TextInput
            changeOnSelect={(trigger, val) => val}
            className="html-builder-text-input input"
            Component="input"
            matchAny={true}
            maxOptions={0}
            offsetY={20}
            onChange={onButtonUrlChange}
            options={autoCompleteOptions}
            placeholder="Enter Button URL"
            trigger="{{"
            value={buttonUrl}
          />
        )}
      </div>
      <hr />
      <div className="module-setting">
        <label>Background Color</label>
        <InputColorSelect className="w-[112px]" onChange={onColorChange('backgroundColor')} value={backgroundColor} />
      </div>
      <div className="module-setting">
        <label>Width</label>
        <FormInput
          inputClass="module-settings-input pixel-input"
          onChange={(e) => onUpdatePercentInputValue(e, 'width')}
          suffix={<span>%</span>}
          type="number"
          value={width}
        />
      </div>
      <div className="module-setting">
        <label>Height</label>
        <FormInput
          inputClass="module-settings-input pixel-input"
          onChange={(e) => onUpdatePixelInputValue(e, 'height')}
          suffix={<span>px</span>}
          type="number"
          value={height}
        />
      </div>
      <div className="module-setting">
        <label>Padding</label>
        <div>
          <PaddingInput
            onPaddingChange={onPaddingChange}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            paddingTop={paddingTop}
          />
        </div>
      </div>
      <div className="module-setting">
        <label>Button Alignment</label>
        <div className="html-builder-settings-icons">
          <TextAlignLeftIcon
            className={`html-builder-settings-button ${buttonAlignment === 'left' ? 'selected' : ''}`}
            onClick={() => onButtonAlignmentChange('left')}
          />
          <TextAlignCenterIcon
            className={`html-builder-settings-button ${buttonAlignment === 'center' ? 'selected' : ''}`}
            onClick={() => onButtonAlignmentChange('center')}
          />
          <TextAlignRightIcon
            className={`html-builder-settings-button ${buttonAlignment === 'right' ? 'selected' : ''}`}
            onClick={() => onButtonAlignmentChange('right')}
          />
        </div>
      </div>
      <p className="modules-header">Border</p>
      <div className="module-setting">
        <label>Color</label>
        <InputColorSelect className="w-[112px]" onChange={onColorChange('borderColor')} value={borderColor} />
      </div>
      <div className="module-setting">
        <label>Width</label>
        <FormInput
          inputClass="module-settings-input pixel-input"
          onChange={(e) => onUpdatePixelInputValue(e, 'borderWidth')}
          suffix={<span>px</span>}
          type="number"
          value={borderWidth}
        />
      </div>
      <div className="module-setting">
        <label>Radius</label>
        <FormInput
          inputClass="module-settings-input pixel-input"
          onChange={(e) => onUpdatePixelInputValue(e, 'borderRadius')}
          suffix={<span>px</span>}
          type="number"
          value={borderRadius}
        />
      </div>
      <p className="modules-header">Text</p>
      <div className="module-setting">
        <label>Font</label>
        <div className="module-settings-select">
          <Select
            aria-label="Select Font"
            classNamePrefix="custom-select"
            id="font-family-select-dropdown"
            name="font-family"
            onChange={onUpdateFontFamily}
            options={fontFamilyOptions}
            value={find(fontFamilyOptions, { value: fontFamily })}
          />
        </div>
      </div>
      <div className="module-setting">
        <label>Font size</label>
        <div className="module-settings-select">
          <CreatableSelect
            aria-label="Select Font Size"
            classNamePrefix="custom-select"
            id="font-size-select-dropdown"
            name="number"
            onChange={onUpdateFontSize}
            options={fontSizeOptions}
            value={find(fontSizeOptions, { value: fontSize })}
          />
        </div>
      </div>
      <div className="module-setting">
        <label>Color</label>
        <InputColorSelect className="w-[112px]" onChange={onColorChange('color')} value={color} />
      </div>
    </div>
  );
}

ButtonSettings.propTypes = {
  buttonUrl: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string,
};

export default DraggableButton;
